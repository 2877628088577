var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
      },
      on: {
        submit: function ($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c("BaseInput", {
            attrs: {
              label: "Start Year",
              placeholder: "Enter Start Year",
              type: "number",
            },
            model: {
              value: _vm.item.start_year,
              callback: function ($$v) {
                _vm.$set(_vm.item, "start_year", $$v)
              },
              expression: "item.start_year",
            },
          }),
          _c("BaseInput", {
            attrs: {
              label: "End Year",
              placeholder: "Enter End Year",
              type: "number",
            },
            model: {
              value: _vm.item.end_year,
              callback: function ($$v) {
                _vm.$set(_vm.item, "end_year", $$v)
              },
              expression: "item.end_year",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }